import type { DayPlan } from '../types';
// import { debugLog } from './utils';

const dateFilter = (planDate: Date, startDate?: Date | null, endDate?: Date | null): boolean => {
	if (!startDate && !endDate) {
		console.log('no start or end date');
		return true;
	} else if (startDate && !endDate) {
		console.log('start date only');
		return planDate >= startDate;
	} else if (endDate && !startDate) {
		console.log('end date only');
		return planDate <= endDate;
	} else if (startDate && endDate) {
		console.log('start and end date');
		return planDate >= startDate && planDate <= endDate;
	} else {
		console.log('no match');
		return true;
	}
};
export const getMealCount = (
	plans: DayPlan[] | null,
	mealId: string,
	startDate?: Date | null,
	endDate?: Date | null
) => {
	const filteredPlans = plans?.filter((plan) => dateFilter(plan.date, startDate, endDate));
	if (!plans) return 0;
	const count = filteredPlans?.filter((plan) => plan.mealId === mealId).length;
	// debugLog('Count for mealId', mealId, 'is', count);
	return count;
};
