<script lang="ts">
	import LinearProgress from '@smui/linear-progress';

	import '../../style/app.css';
	import auth from '$stores/authStore';
	import userStore from '$stores/userStore';
	import mealStore from '$stores/mealStore';
	import { defaultMeals } from '$db/mealDao';
	import { isDemoUser } from '$lib/utils';

	import TabSwitcher from '$lib/TabSwitcher.svelte';
	import Meals from '$lib/Meals.svelte';
	import type { AppTab } from '../../types';
	import Plans from '$lib/Plans.svelte';
	import Reports from '$lib/Reports.svelte';

	let tabs: AppTab[] = [
		{
			key: 'plans',
			label: 'Meal Plans',
			icon: 'access_time'
		},
		{
			key: 'meals',
			label: 'Meals',
			icon: 'near_me'
		},
		{
			key: 'reports',
			label: 'Reports',
			icon: 'favorite'
		}
	];
	let active = tabs[0];

	$: {
		if (!isDemoUser($userStore.user)) {
			// Updating the tab badge breaks Shepherd
			const mealsCount = $mealStore.meals.length - defaultMeals.length;
			if (mealsCount > 0) {
				tabs[1].badge = mealsCount;
			}
		}
	}
</script>

{#await auth.waitForFirebase}
	Loading...
{:then user}
	{#if $auth.user && $userStore.user && $userStore.user.placeId}
		<TabSwitcher {tabs} bind:active />
		<!-- <p>User: {JSON.stringify($auth.user.uid)}</p> -->
		{#if active.key === 'plans'}
			<Plans />
		{:else if active.key === 'meals'}
			<Meals />
		{:else if active.key === 'reports'}
			<Reports />
		{/if}
	{:else}
		<div style="display: flex; justify-content: center">
			<LinearProgress indeterminate />
		</div>
	{/if}
{/await}
