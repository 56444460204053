<script lang="ts">
	import type { DayPlan, Meal } from '../types';
	import userStore from '$stores/userStore';
	import mealStore from '$stores/mealStore';
	import planStore from '$stores/planStore';
	import { updatePlans } from '$db/planDao';

	import { debugLog, prettyPrintDate } from './utils';
	import DayMealSelector from './DayMealSelector.svelte';

	export let startDate = new Date();

	const flipDurationMs = 200;
	let placeId = $userStore?.user?.placeId || '';
	let days: Date[] = [];
	let dayPlans: DayPlan[] = [];

	$: {
		placeId = $userStore?.user?.placeId || '';
		days = [0, 1, 2, 3, 4, 5, 6].map((i) => {
			const nextDay = new Date(startDate.getTime());
			// see https://stackoverflow.com/questions/3674539/incrementing-a-date-in-javascript
			nextDay.setDate(startDate.getDate() + i);
			return nextDay;
		});
		dayPlans = days.map((day, i) => {
			const matches = !$planStore.plans
				? []
				: $planStore.plans.filter((p) => {
						const match =
							p.date.getDate() == day.getDate() &&
							p.date.getMonth() == day.getMonth() &&
							p.date.getFullYear() == day.getFullYear();
						return match;
				  });
			if (matches.length > 0) {
				const plan = matches[0];
				return plan;
			} else {
				const plan = {
					id: `${i}`,
					date: day,
					isSaved: false
				};
				return plan;
			}
		});
		debugLog('WeekOfMeals days for', prettyPrintDate(startDate), dayPlans);
	}

	/* Used to disable drag-and-drop styling on mobile */
	$: isDesktop = matchMedia('(pointer:fine)').matches;
	$: isMobile = matchMedia('(pointer:coarse)').matches;
	let enableDrag: Boolean = true;
	let hovering: null | number = null;
	let dragging: null | number = null;

	const enableDropping = (event: DragEvent) => {
		// https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/Drag_operations#specifying_drop_targets
		event.preventDefault();
	};

	const moveDay = async (indexToMove: number, indexToInsertInto: number) => {
		const newTracklist = dayPlans;
		if (indexToMove < indexToInsertInto) {
			newTracklist.splice(indexToInsertInto + 1, 0, newTracklist[indexToMove]);
			newTracklist.splice(indexToMove, 1);
		} else {
			newTracklist.splice(indexToInsertInto, 0, newTracklist[indexToMove]);
			newTracklist.splice(indexToMove + 1, 1);
		}
		for (let i = 0; i < newTracklist.length; i++) {
			newTracklist[i].date = days[i];
		}
		/* If we just wanted to swap, we could just swap the date values and re-save, but we want to insert */
		// newTracklist[target].date = days[dragging];
		// newTracklist[dragging].date = days[target];
		await updatePlans(placeId, newTracklist);
	};

	const drop = (event: DragEvent, target: number) => {
		if (!event || !event.dataTransfer) return;
		if (hovering === dragging) {
			hovering = null;
			dragging = null;
			return;
		}
		event.dataTransfer.dropEffect = 'move';
		/* Note: don't need to get data because we can track the index of the dragged item in a local variable */
		// const dragging = parseInt(event.dataTransfer.getData('text/plain'));

		/* we need to re-order the list so we can update all the dates in order to 'insert' instead
       of swapping the dragged item with the target item */
		if (dragging !== null) {
			moveDay(dragging, target);
		}
		hovering = null;
		dragging = null;
	};

	const dragstart = (event: DragEvent, i: number) => {
		if (!event || !event.dataTransfer) return;
		event.dataTransfer.effectAllowed = 'move';
		event.dataTransfer.dropEffect = 'move';
		dragging = i;
		/* Note: don't need to set data because we can track the index of the dragged item in a local variable */
		// event.dataTransfer.setData('text/plain', dragging);
	};
</script>

{#if placeId}
	{#if $mealStore.meals.length === 0}
		Please add Meals on the Meals tab first
	{:else}
		<div class="plans">
			{#each dayPlans as day, index (day.id)}
				{@const moveUp = index === 0 ? null : () => moveDay(index, index - 1)}
				{@const moveDown = index === dayPlans.length - 1 ? null : () => moveDay(index, index + 1)}
				<div class="date">{prettyPrintDate(days[index])}</div>
				<!-- //https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/draggable -->
				<div
					draggable={enableDrag && isDesktop ? 'true' : 'false'}
					on:dragover={enableDropping}
					on:dragstart={(event) => dragstart(event, index)}
					on:drop|preventDefault={(event) => drop(event, index)}
					on:dragenter={() => (hovering = index)}
					class="plan-info"
					class:draggable={enableDrag && isDesktop}
					class:is-hovering-top={dragging !== null &&
						dragging !== index &&
						dragging > index &&
						hovering === index}
					class:is-hovering-bottom={dragging !== null &&
						dragging !== index &&
						dragging < index &&
						hovering === index}
					class:is-dragging={dragging === index}>
					<DayMealSelector {placeId} bind:enableDrag {day} {moveUp} {moveDown} />
				</div>
			{/each}
		</div>
	{/if}
{/if}

<style>
	.plans {
		width: 100%;
		display: grid;
		grid-template-rows: auto;
		gap: 10px;
		align-items: center;
	}

	.draggable {
		cursor: move;
	}
	.draggable.is-hovering-top {
		padding-top: 10px;
		border-top: 4px solid var(--theme-primary);
	}
	.draggable.is-hovering-bottom {
		padding-bottom: 10px;
		border-bottom: 4px solid var(--theme-primary);
	}
	.draggable.is-dragging {
		opacity: 0.5;
		border: 1px dashed var(--theme-primary);
	}

	/* media query */
	@media (min-width: 992px) {
		.plans {
			grid-template-columns: 120px auto;
			padding-left: 4px;
		}
	}
	.date {
		font-weight: bold;
		font-size: 1.25em;
		margin-top: 4px;
	}
</style>
