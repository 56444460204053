<script lang="ts">
	import Textfield from '@smui/textfield';
	import { Content } from '@smui/dialog';
	import IconButton, { Icon } from '@smui/icon-button';

	import { mdiLaunch } from '@mdi/js';
	import SvgIcon from '$lib/SvgIcon.svelte';

	import type { Meal, Ingredient } from '../types';
	import IngredientsGrid from './Ingredients.svelte';

	export let meal: Meal;

	function openUrl() {
		if (!meal.url) return;
		if (!meal.url.startsWith('http://') && !meal.url.startsWith('https://')) {
			meal.url = 'https://' + meal.url;
		}
		window.open(meal.url, '_blank');
	}
</script>

<Content id="simple-content">
	<div class="form-field-container">
		<Textfield
			variant="outlined"
			style="width: 100%;"
			bind:value={meal.name}
			label="Name"
			id="new-meal-name" />
	</div>
	<div class="url-area">
		<div class="form-field-container" style:flex-grow={meal.url ? 0.9 : 1}>
			<Textfield
				variant="outlined"
				style="width: 100%;"
				bind:value={meal.url}
				label="URL"
				id="new-meal-url" />
		</div>
		{#if meal.url}
			<IconButton on:click={openUrl} title="Open in new tab" style="padding: 6px;"
				><SvgIcon mdiIcon={mdiLaunch} /></IconButton>
		{/if}
	</div>
	<div class="form-field-container">
		<IngredientsGrid bind:ingredients={meal.ingredients} />
	</div>
</Content>

<style>
	:global(.smui-card__content),
	:global(.mdc-dialog .mdc-dialog__content) {
		padding: 0;
	}
	.form-field-container {
		margin: 1rem 1rem;
	}
	.url-area {
		display: flex;
		align-items: center;
	}
</style>
