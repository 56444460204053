<script lang="ts">
	import type { DayPlan, Ingredient, Meal } from '../types';
	import userStore from '$stores/userStore';
	import mealStore, { isDefaultMeal } from '$stores/mealStore';
	import { toggleIngredient } from '$db/planDao';
	import { debugLog } from './utils';
	import { mdiContentCopy, mdiLaunch } from '@mdi/js';
	import SvgIcon from './SvgIcon.svelte';
	import CheckItem from './CheckItem.svelte';

	export let plan: DayPlan | undefined;
	export let count: number | undefined;

	const meal = $mealStore.meals.filter((meal: Meal) => meal.id === plan?.mealId)[0];
	$: allChecked = plan?.checkedItems?.length === meal?.ingredients?.length;

	const toggleMealIngredient = (ingredient: Ingredient, e: Event) => {
		const placeId = $userStore?.user?.placeId || '';
		const planId = plan?.id || '';
		const ingredientId = ingredient.id;
		const isChecked = ingredient?.id !== undefined && plan?.checkedItems?.includes(ingredient.id);
		debugLog(`toggleMealIngredient: ${placeId} ${planId}:`, ingredient, !isChecked);
		toggleIngredient(placeId, planId, ingredientId, !isChecked);
	};
	const copyMealIngredient = (ingredient: Ingredient, e: Event) => {
		if (ingredient?.product) {
			navigator.clipboard.writeText(ingredient.product);
		}
	};
</script>

{#if !isDefaultMeal(meal)}
	<div class="meal" class:allChecked>
		<div class="meal-name">
			{#if meal?.url}
				<div class="name-link" title="Open URL for Meal">
					<a href={meal.url}>{meal?.name}</a>
					<SvgIcon mdiIcon={mdiLaunch} style="width:16px" />
				</div>
			{:else}
				{meal?.name}
			{/if}
			{#if count && count > 1}
				<span class="meal-count"> (x{count})</span>
			{/if}
		</div>
		{#if meal?.ingredients}
			<ul class="meal-ingredients">
				{#each meal.ingredients as ingredient}
					{@const isChecked =
						ingredient?.id !== undefined && plan?.checkedItems?.includes(ingredient.id)}
					<CheckItem
						item={ingredient}
						label={ingredient.product || ''}
						amount={ingredient.amount}
						{isChecked}
						toggleItem={toggleMealIngredient} />
				{/each}
			</ul>
		{/if}
	</div>
{/if}

<style>
	.meal-name {
		font-weight: bold;
		margin-top: 10px;
	}
	.name-link {
		display: flex;
		gap: 4px;
	}
	@media print {
		.name-link > a {
			text-decoration: none;
			color: unset;
		}
		:global(.name-link > svg) {
			display: none;
		}
		.allChecked {
			display: none;
		}
	}
</style>
