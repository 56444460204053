<script lang="ts">
	import { DateInput } from 'date-picker-svelte';
	import { mdiEraser } from '@mdi/js';

	import Button from '@smui/button';
	import SvgIcon from './SvgIcon.svelte';

	import userStore from '$stores/userStore';
	import mealStore from '$stores/mealStore';
	import planStore from '$stores/planStore';

	import { getMealCount } from '$lib/reportHelper';
	import ReportsHistogram from './ReportsHistogram.svelte';
	import type { DateRangeFilter, Meal, MealCountEntry } from '../types';

	const placeId = $userStore?.user?.placeId || '';

	let dateRange: DateRangeFilter = {
		start: new Date(new Date().setDate(new Date().getDate() - 180)),
		end: new Date()
	};
	const clearDateRange = () => {
		dateRange = {
			start: null,
			end: null
		};
	};
	const compareCounts = (a: MealCountEntry | undefined, b: MealCountEntry | undefined) => {
		if (!a || !b) {
			return 0;
		}
		return b.count - a.count;
	};
	const getCount = (meal: Meal, range: DateRangeFilter) => {
		if (meal.id) {
			let count = getMealCount($planStore.plans, meal.id, range.start, range.end);
			return { mealId: meal.id, count: count || 0 } as MealCountEntry;
		} else {
			return { mealId: '', count: 0 } as MealCountEntry;
		}
	};
	const filterInvalidMeals = (mealCount: MealCountEntry) => mealCount.mealId !== '';

	$: mealCounts = $mealStore.meals
		.map((meal) => getCount(meal, dateRange))
		.filter(filterInvalidMeals)
		.sort(compareCounts);
</script>

<h3>Number of times you've used each meal</h3>
<div class="dateFilter">
	<div class="label">Start Date:</div>
	<DateInput
		bind:value={dateRange.start}
		format="MM/dd/yyyy"
		placeholder="Select a start date"
		closeOnSelection={true} />
	<div class="label">End Date:</div>
	<DateInput
		bind:value={dateRange.end}
		format="MM/dd/yyyy"
		placeholder="Select a end date"
		closeOnSelection={true} />
	<Button on:click={clearDateRange} title="Clear start and end date">
		<SvgIcon mdiIcon={mdiEraser} />
	</Button>
</div>

<!-- Destroy and recreate the ReportsHistogram because otherwise the chartjs chart doesn't re-render -->
{#key mealCounts}
	<ReportsHistogram {mealCounts} />
{/key}

<style>
	h3 {
		text-align: center;
		margin: 0.5rem 1rem 1rem 1rem;
	}
	.dateFilter {
		display: flex;
		justify-content: center;
		margin: 0.5rem 1rem 1rem 1rem;
		gap: 1rem;
		align-items: center;
	}
	.label {
		margin-left: 1rem;
	}
</style>
