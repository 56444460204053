<script lang="ts">
	import Tab, { Icon, Label } from '@smui/tab';
	import TabBar from '@smui/tab-bar';
	import Badge from '$lib/Badge.svelte';

	import type { AppTab } from '../types';

	export let tabs: AppTab[];
	export let active: AppTab;
</script>

<div class="tab-bar">
	<TabBar {tabs} let:tab bind:active class="tab-bar">
		<Tab {tab} id="tab-{tab.key}">
			<Label>
				<span style="position: relative;" class="tab-{encodeURI(tab.label)}">
					{tab.label}
					{#if tab.badge !== undefined}
						<Badge color={tab.badge > 0 ? '' : 'error'}>&nbsp;{tab.badge}</Badge>
					{/if}
				</span>
			</Label>
		</Tab>
	</TabBar>
</div>

<style>
	:global(.tab-bar) {
		background-color: white;
		border-radius: 4px;
		border-radius: var(--mdc-shape-medium, 4px);
		background-color: #fff;
		background-color: var(--mdc-theme-surface, #fff);
		position: relative;
		box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
			0px 1px 3px 0px rgba(0, 0, 0, 0.12);
		box-sizing: border-box;
	}
	:global(.smui-badge--color-error) {
		background-color: var(--theme-error, #f44336);
		color: white;
	}
	@media print {
		:global(.tab-bar) {
			display: none;
		}
	}
</style>
