<script lang="ts">
	import { fade } from 'svelte/transition';

	import Card from '@smui/card';
	import { Label } from '@smui/list';
	import Button from '@smui/button';
	import { mdiArrowLeft, mdiArrowRight, mdiCalendarToday } from '@mdi/js';

	import WeekOfMeals from '$lib/WeekOfMeals.svelte';
	import ShoppingList from '$lib/ShoppingList.svelte';
	import { debugLog, get12MonthRange, getNextDayOfTheWeek } from '$lib/utils';
	import SvgIcon from './SvgIcon.svelte';
	import { prettyPrintDate } from './utils';
	import { dateStore } from '$stores/dateStore';

	const { sixMonthsAgo, sixMonthsFromNow } = get12MonthRange();
	let isPlansCollapsed = false;

	/* Keep track of the current week to determine whether to show Jump to Today button */
	let today = new Date();
	let lastWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
	let nextWeek = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000);
	let startOfThisWeek = getNextDayOfTheWeek('Sunday', true, lastWeek);

	/* Gets the next Sunday after last week aka the start of this week */
	$: prevWeek = new Date($dateStore.getTime() - 7 * 24 * 60 * 60 * 1000);
	$: startDate = getNextDayOfTheWeek('Sunday', true, prevWeek);
	$: endDate = new Date($dateStore.getTime() + 7 * 24 * 60 * 60 * 1000);
	$: prettyDate = prettyPrintDate(startDate);
	$: isThisWeek = startDate && startDate < today && endDate > today;
	$: isNextWeek = startDate && startDate < nextWeek && endDate > nextWeek;

	function togglePlans() {
		isPlansCollapsed = !isPlansCollapsed;
	}
</script>

<div class="card-container week-bar">
	<Card>
		<div class="week-actions">
			<Button
				on:click={() => dateStore.adjustDate(-7)}
				disabled={!startDate || startDate <= sixMonthsAgo}>
				<SvgIcon mdiIcon={mdiArrowLeft} />
				<Label><span class="prev">Prev</span></Label>
			</Button>
			{#key prettyDate}
				<h3 class="week-label" in:fade={{ delay: 250, duration: 250 }} out:fade={{ duration: 0 }}>
					{#if isThisWeek}
						<span class="weekDisplay">This Week</span>
					{:else if isNextWeek}
						<span class="weekDisplay">Next Week</span>
					{:else}
						<span class="weekPreamble">Week starting </span>
						<span class="weekDisplay">{prettyDate}</span>
					{/if}
					{#if startDate?.getTime() != startOfThisWeek?.getTime()}
						<Button
							on:click={() => dateStore.set(new Date())}
							class="today-button"
							title="Jump to This Week"><SvgIcon mdiIcon={mdiCalendarToday} /></Button>
					{/if}
				</h3>
			{/key}
			<Button
				on:click={() => dateStore.adjustDate(7)}
				disabled={!endDate || endDate >= sixMonthsFromNow}>
				<SvgIcon mdiIcon={mdiArrowRight} />
				<Label><span class="next">Next</span></Label>
			</Button>
		</div>
	</Card>
</div>
<div class="main-grid">
	<h2
		class="plans-header"
		class:collapsed={isPlansCollapsed}
		on:click={togglePlans}
		on:keypress={(e) => e.key === 'Enter' && togglePlans()}
		title="Click to expand/collapse"
		tabIndex="0"
		role="button">
		Plans
	</h2>
	<div class="week-wrapper">
		{#if !isPlansCollapsed}
			<div class="week-of-meals" transition:fade={{ duration: 250 }}>
				<WeekOfMeals {startDate} />
			</div>
		{/if}
		<div class="shopping-list"><ShoppingList {startDate} /></div>
	</div>
</div>

<style>
	.card-container {
		padding: 10px 16px;
	}
	.main-grid {
		display: flex;
		flex-direction: column;
		gap: 10px;
		padding: 2px 16px;
	}
	.week-wrapper {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 10px;
	}
	.week-actions {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 6px;
	}
	.week-of-meals {
		flex: 6;
		padding-top: 4px;
	}
	.shopping-list {
		flex: 4;
		min-width: 200px;
		padding: 10px;
		border-radius: 4px;
		border-radius: var(--mdc-shape-medium, 4px);
		background-color: #fff;
		background-color: var(--mdc-theme-surface, #fff);
		position: relative;
		box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
			0px 1px 3px 0px rgba(0, 0, 0, 0.12);
		margin-top: 4px;
		box-sizing: border-box;
	}
	.week-label {
		line-height: 1.8;
	}
	.weekPreamble {
		display: none;
	}
	.plans-header {
		cursor: pointer;
	}
	.plans-header::before {
		content: url('/images/caret-down.svg');
	}
	.plans-header.collapsed::before {
		content: url('/images/caret-right.svg');
	}
	:global(.today-button) {
		min-width: 32px;
		max-height: 16px;
	}
	:global(.today-button .mdc-button__icon) {
		margin: 0;
	}
	:global(.main-content.swipingRight .next) {
		font-weight: bolder !important;
	}
	:global(.main-content.swipingLeft .prev) {
		font-weight: bolder !important;
	}
	@media (min-width: 992px) {
		.weekPreamble {
			display: inline;
		}
		.main-grid,
		.week-wrapper {
			flex-direction: row;
		}
		.plans-header {
			display: none;
		}
	}
	@media print {
		.shopping-list {
			box-shadow: none;
		}
		.week-bar {
			display: none;
		}
		.week-actions {
			justify-content: center;
		}
		:global(.week-actions button) {
			display: none;
		}
		.week-of-meals {
			display: none;
		}
		:global(.mdc-layout-grid__inner) {
			grid-template-columns: 1fr;
			margin-left: 4px;
		}
		.plans-header {
			display: none;
		}
	}
</style>
