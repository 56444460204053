import type { ExtraItem } from '../types';

import {
	addDoc,
	collection,
	doc,
	orderBy,
	query,
	DocumentSnapshot,
	type SnapshotOptions,
	getDocs,
	getDoc,
	onSnapshot,
	type Unsubscribe,
	setDoc,
	type FirestoreDataConverter,
	deleteDoc,
	where
} from 'firebase/firestore';
import { Timestamp } from 'firebase/firestore';
import { db } from '../firebase';
import { debugLog } from '$lib/utils';

const ExtraItemConverter: FirestoreDataConverter<ExtraItem> = {
	toFirestore: (item: ExtraItem) => {
		return {
			name: item.name,
			isChecked: item.isChecked,
			// Firebase expects a Timestamp object, which is not a Date object.
			createdOn: Timestamp.fromDate(item.createdOn),
			date: item.date instanceof Date ? Timestamp.fromDate(item.date) : item.date
		};
	},
	fromFirestore: (snapshot: DocumentSnapshot, options: SnapshotOptions | undefined) => {
		const data = snapshot.data(options);
		return {
			id: snapshot.id,
			name: data?.name,
			isChecked: !!data?.isChecked,
			// Firebase returns a Timestamp object, which is not a Date object.
			createdOn: data?.createdOn?.toDate(),
			date: data?.date instanceof Timestamp ? data?.date.toDate() : data?.date
		};
	}
};

const getExtraItems = async (placeId: string, date: Date) => {
	const basePath = `places/${placeId}/extraItems`;
	const ref = collection(db, basePath).withConverter(ExtraItemConverter);

	const q = query(ref, where('date', '==', Timestamp.fromDate(date)), orderBy('createdOn', 'asc'));
	const querySnapshot = await getDocs(q);
	debugLog(`Found ${querySnapshot.size} extra items for ${placeId}`);
	const items = querySnapshot.docs.map((doc) => doc.data() as ExtraItem);
	return items;
};

const upsertExtraItem = async (placeId: string, item: ExtraItem) => {
	debugLog('Upserting ExtraItem for ' + placeId, item);
	const basePath = `places/${placeId}/extraItems`;
	if (item.id) {
		const ref = doc(db, basePath + '/' + item.id).withConverter(ExtraItemConverter);
		await setDoc(ref, item);
		return item;
	} else {
		if (!item.createdOn) {
			item.createdOn = new Date();
		}
		const collectionRef = collection(db, basePath).withConverter(ExtraItemConverter);
		const docRef = await addDoc(collectionRef, item);
		const docSnapshot = await getDoc(docRef);
		return docSnapshot.data() as ExtraItem;
	}
};

const deleteExtraItem = async (placeId: string, itemId: string) => {
	debugLog('Deleting extra item for ' + placeId, itemId);
	const basePath = `places/${placeId}/extraItems/${itemId}`;
	const ref = doc(db, basePath);
	return deleteDoc(ref);
};

const listenForExtraItems = (placeId: string, callback: (items: ExtraItem[]) => void) => {
	try {
		const basePath = `places/${placeId}/extraItems`;
		const ref = collection(db, basePath).withConverter(ExtraItemConverter);
		const q = query(ref, orderBy('createdOn', 'asc'));
		const unsubscribe: Unsubscribe = onSnapshot(q, (querySnapshot) => {
			const items = querySnapshot.docs.map((doc) => doc.data() as ExtraItem);
			callback(items);
		});
		return unsubscribe;
	} catch (error) {
		console.error('db/extraItemDao: Error listening for extra items', error);
	}
};

export { getExtraItems, upsertExtraItem, deleteExtraItem, listenForExtraItems };
