<script lang="ts">
	import { onMount, onDestroy } from 'svelte';

	import Button, { Label } from '@smui/button';
	import Dialog, { Title, Content, Actions } from '@smui/dialog';

	import mealStore, { isDefaultMeal } from '$stores/mealStore';
	import MealCard from '$lib/Meal.svelte';
	import EditMeal from '$lib/EditMeal.svelte';
	import { upsertMeal } from '$db/mealDao';
	import type { Ingredient, Meal } from '../types';
	import { mdiChefHat } from '@mdi/js';
	import SvgIcon from '$lib/SvgIcon.svelte';
	import userStore from '$stores/userStore';
	import { debugLog, DEFAULT_TOUR_OPTIONS } from './utils';

	let openNewMealDialog = false;
	const DEFAULT_MEAL: Meal = {
		name: '',
		createdOn: new Date(),
		url: '',
		description: '',
		ingredients: [
			{
				id: '1',
				product: '',
				amount: ''
			}
		]
	};
	let newMeal = structuredClone(DEFAULT_MEAL);

	function resetMeal() {
		debugLog('Reset meal to ', DEFAULT_MEAL);
		newMeal = structuredClone(DEFAULT_MEAL);
	}

	function cancelAddMeal() {
		openNewMealDialog = false;
		resetMeal();
	}

	async function addNewMeal() {
		newMeal.createdOn = new Date();
		const placeId = $userStore?.user?.placeId || '';
		newMeal = await upsertMeal(placeId, newMeal);
		debugLog('New meal added', newMeal);
		resetMeal();
	}

	$: {
		debugLog('Mealstore meals', $mealStore.meals);
	}

	function onShepherdEvent(e: any) {
		debugLog('onShepherdEvent', e?.data);
		if (e?.data?.type === 'shepherdEvent') {
			if (e?.data?.mealName) {
				newMeal.name = e?.data?.mealName;
			} else if (e?.data?.newIngredients) {
				newMeal.ingredients = e?.data?.newIngredients;
			}
		}
	}

	onMount(() => {
		window.addEventListener('message', onShepherdEvent);
	});

	onDestroy(() => {
		window.removeEventListener('message', onShepherdEvent);
	});
</script>

<Dialog
	bind:open={openNewMealDialog}
	aria-labelledby="simple-title"
	aria-describedby="simple-content"
	surface$style="width: 550px; max-width: calc(100vw - 32px);"
	on:shepherdEvent={(event) => alert('Shepherd event' + event)}>
	<!-- Title cannot contain leading whitespace due to mdc-typography-baseline-top() -->
	<div class="form-title"><Title>Add Meal</Title></div>
	<EditMeal bind:meal={newMeal} />
	<Actions>
		<Button on:click={cancelAddMeal}>
			<Label>Cancel</Label>
		</Button>
		<Button on:click={addNewMeal} variant="raised" id="save-new-meal-button">
			<Label>Save</Label>
		</Button>
	</Actions>
</Dialog>

<div class="meals">
	<div class="button-bar">
		<Button on:click={() => (openNewMealDialog = true)} variant="raised" id="add-meal-button">
			<SvgIcon mdiIcon={mdiChefHat} />
			<Label>Add Meal</Label>
		</Button>
	</div>
	<div class="cards">
		{#if $mealStore.loading}
			Loading...
		{:else}
			{#each $mealStore.meals as meal}
				{#if !isDefaultMeal(meal)}
					<MealCard {meal} />
				{/if}
			{/each}
		{/if}
	</div>
</div>

<style>
	.meals {
		padding: 20px;
	}

	.button-bar {
		display: flex;
		gap: 10px;
		margin-bottom: 1rem;
	}
	.cards {
		display: flex;
		flex-wrap: wrap;
		justify-content: start;
		gap: 10px;
	}
	:global(.mdc-dialog--open) {
		top: unset;
		bottom: 0;
	}
</style>
