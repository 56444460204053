import { derived } from 'svelte/store';
import auth from './authStore';
import userStore from './userStore';
import { listenForMeals, defaultMeals } from '$db/mealDao';
import type { Meal, MealStore } from '../types';
import { get } from 'svelte/store';

const store = derived(
	[auth, userStore],
	([$auth, $userStore], set: (value: MealStore) => void) => {
		if ($auth.user && $userStore?.user?.placeId) {
			const unsubscribe = listenForMeals($userStore?.user?.placeId, (meals) => {
				set({
					loading: false,
					error: null,
					meals: meals
				});
			});
			return unsubscribe;
		} else {
			set({
				loading: false,
				error: 'You must be logged in to view plans.',
				meals: []
			});
		}
	},
	{ loading: true, error: null, meals: [] }
);

export default store;

export const isDefaultMeal = (meal: Meal) => meal && meal.id && isDefaultMealId(meal.id);

export const isDefaultMealId = (mealId: string) =>
	!!defaultMeals.find((defaultMeal) => defaultMeal.id === mealId);

export const getMealById = (mealId: string) => {
	const meal = get(store).meals.find((meal) => meal.id === mealId);
	return meal;
};
