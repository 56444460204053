<script lang="ts">
	import { slide, fade } from 'svelte/transition';
	import Card, { Content } from '@smui/card';
	import Fab from '@smui/fab';
	import Dialog, { Title, Actions } from '@smui/dialog';
	import Button, { Label } from '@smui/button';

	import {
		mdiArrowExpand,
		mdiArrowCollapse,
		mdiContentSave,
		mdiDelete,
		mdiPlaylistEdit
	} from '@mdi/js';
	import SvgIcon from '$lib/SvgIcon.svelte';
	import userStore from '$stores/userStore';
	import type { Meal } from '../types';
	import EditMeal from '$lib/EditMeal.svelte';

	import { upsertMeal, deleteMeal } from '$db/mealDao';
	import Textfield from '@smui/textfield';

	export let meal: Meal;
	let expanded = false;
	let confirmDeleteDialogOpen = false;
	let openEditMealDialog = false;

	function toggleVisibility() {
		if (openEditMealDialog) {
			openEditMealDialog = false;
		} else {
			openEditMealDialog = true;
		}
	}

	function saveMeal() {
		const placeId = $userStore?.user?.placeId || '';
		upsertMeal(placeId, meal);
		expanded = false;
	}

	function removeMeal() {
		if (meal.id) {
			const placeId = $userStore?.user?.placeId || '';
			deleteMeal(placeId, meal.id);
		}
	}
</script>

<Dialog
	bind:open={confirmDeleteDialogOpen}
	aria-labelledby="simple-title"
	aria-describedby="simple-content">
	<!-- Title cannot contain leading whitespace due to mdc-typography-baseline-top() -->
	<Title id="simple-title">Are you sure?</Title>
	<Content id="simple-content">
		<p class="delete-confirmation">Are you sure you want to delete {meal.name}?</p>
	</Content>
	<Actions>
		<Button on:click={() => (confirmDeleteDialogOpen = false)}>
			<Label>No</Label>
		</Button>
		<Button
			on:click={() => {
				removeMeal();
				confirmDeleteDialogOpen = false;
			}}>
			<Label>Yes</Label>
		</Button>
	</Actions>
</Dialog>

<Dialog
	bind:open={openEditMealDialog}
	aria-labelledby="simple-title"
	aria-describedby="simple-content"
	surface$style="width: 550px; max-width: calc(100vw - 32px);">
	<div class="form-title"><Title>Edit Meal</Title></div>
	<Content id="simple-content">
		<EditMeal bind:meal />
	</Content>
	<Actions>
		<Button on:click={() => console.log('Cancel')}>
			<Label>Cancel</Label>
		</Button>
		<Button on:click={saveMeal} variant="raised" id="save-new-meal-button">
			<Label>Save</Label>
		</Button>
	</Actions>
</Dialog>

<div class="card-container" class:expanded>
	<Card>
		<div>
			<h3
				on:click={toggleVisibility}
				on:keypress={(e) => {
					console.log('card container keypress', e.key);
					if (e.key === 'e') {
						toggleVisibility();
					} else if (e.key === 'd') {
						confirmDeleteDialogOpen = true;
					}
				}}
				role="button"
				tabIndex="0"
				title="Enter 'e' to edit, 'd' to delete">
				{meal.name}
			</h3>
			<div
				class="fab-container top right"
				on:click|stopPropagation={toggleVisibility}
				on:keypress|stopPropagation>
				<Fab mini color="secondary" title="Edit">
					<SvgIcon mdiIcon={mdiPlaylistEdit} />
				</Fab>
			</div>
			<div
				class="fab-container top left"
				on:click={() => {
					confirmDeleteDialogOpen = true;
				}}
				on:keypress|stopPropagation>
				<Fab mini class="delete-button" title="Delete">
					<SvgIcon mdiIcon={mdiDelete} />
				</Fab>
			</div>
		</div>
	</Card>
</div>

<style>
	h3 {
		text-align: center;
		cursor: pointer;
		padding: 20px;
	}
	h3:hover {
		background-color: var(--card-background-color);
	}
	.card-container.expanded h3:hover {
		background-color: unset;
	}
	.card-container {
		width: 500px;
	}
	.card-container:hover {
		--card-background-color: var(--theme-secondary);
	}
	.card-container:hover .fab-container,
	.card-container:focus .fab-container,
	.fab-container:focus {
		display: block;
	}

	.fab-container {
		display: none;
		position: absolute;
	}
	.fab-container.top {
		top: 12px;
	}
	.fab-container.right {
		right: 10px;
	}
	.fab-container.left {
		left: 10px;
	}
	:global(.delete-button) {
		background-color: lightgray;
	}
	:global(.delete-button:hover) {
		background-color: lightcoral;
	}
	.delete-confirmation {
		padding: 0 24px 9px;
	}
</style>
