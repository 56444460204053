<script lang="ts">
	import { flip } from 'svelte/animate';
	import Card from '@smui/card';
	import Select, { Option } from '@smui/select';
	import Textfield from '@smui/textfield';
	import HelperText from '@smui/textfield/helper-text';
	import { mdiArrowUp, mdiArrowDown, mdiDrag } from '@mdi/js';
	import type { DayPlan } from '../types';
	import { savePlan } from '$db/planDao';
	import mealStore from '$stores/mealStore';

	import Button from '@smui/button';
	import SvgIcon from './SvgIcon.svelte';

	export let placeId: string;
	export let readOnly = false;
	export let enableDrag: Boolean | undefined = true;

	export let day: DayPlan;
	export let moveUp: null | (() => Promise<void>);
	export let moveDown: null | (() => Promise<void>);

	let mealSelection: string | undefined = day.mealId;

	async function onSelect() {
		day.mealId = mealSelection;
		await savePlan(placeId, day);
	}
	async function onNotesChange(plan: DayPlan, element: EventTarget | null) {
		const noteValue = (element as HTMLInputElement).value;
		plan.notes = noteValue;
		await savePlan(placeId, plan);
	}
</script>

<Card padded title="Drag to reorder">
	<div class="card-inner">
		<div class="plan">
			<div class="meal">
				{#if readOnly}
					<p>{day?.meal?.name || 'No Meal'}</p>
				{:else}
					<!-- don't use SMUI Select because of https://github.com/hperrin/svelte-material-ui/issues/242 -->
					<select
						style="width: 100%;"
						class="daymeal"
						class:empty={!day.mealId}
						bind:value={mealSelection}
						on:change={onSelect}>
						<option value="" data-placeholder>No Meal Selected</option>
						{#each $mealStore.meals as meal}
							<option class="daymealoption" value={meal.id} selected={mealSelection === meal.id}>
								{meal.name}
							</option>
						{/each}
					</select>
				{/if}
			</div>
			<div class="notes">
				<!-- don't use bind here because notes are re-evaled from firebase automatically -->
				<!-- disable dragging when interacting with textarea -->
				<Textfield
					variant="outlined"
					label="Notes"
					style="width: 100%;"
					on:change={(e) => onNotesChange(day, e.target)}
					on:focus={() => (enableDrag = false)}
					on:blur={() => (enableDrag = true)}
					value={day.notes || ''} />
			</div>
		</div>
		<!-- only visible on mobile -->
		<div class="movers">
			<Button on:click={() => moveUp && moveUp()} disabled={!moveUp}>
				<SvgIcon mdiIcon={mdiArrowUp} />
			</Button>
			<Button on:click={() => moveDown && moveDown()} disabled={!moveDown}>
				<SvgIcon mdiIcon={mdiArrowDown} />
			</Button>
		</div>
		<div class="grabber">
			<SvgIcon mdiIcon={mdiDrag} />
		</div>
	</div>
</Card>

<style>
	.plan {
		display: grid;
		align-items: center;
		gap: 10px;
	}
	.card-inner {
		display: grid;
		grid-template-columns: auto 40px;
	}
	.movers {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	.grabber {
		display: none;
	}
	/* media query */
	@media (min-width: 992px) {
		.plan {
			grid-template-columns: auto auto;
		}
		.notes {
			margin-top: 0px !important;
		}
		.card-inner {
			grid-template-columns: auto 15px;
		}
		.movers {
			display: none;
		}
		.grabber {
			display: flex;
			flex-direction: column;
			justify-content: center;
			margin-right: -10px;
			padding-left: 5px;
		}
	}
	.daymeal {
		width: 100%;
		border-radius: 4px;
		line-height: 1.5;
		height: 56px;
		display: block;
		font-size: 16px;
		font-family: sans-serif;
		color: rgb(0, 0, 0, 0.9);
		padding: 0.6em 1.4em 0.5em 0.8em;
		max-width: 100%;
		box-sizing: border-box;
		margin: 0;
		border: 1px solid #aaa;
		/* box-shadow: 0 1px 0 1px rgba(0,0,0,.04); */
		/* border-radius: .5em; */
		-moz-appearance: none;
		-webkit-appearance: none;
		appearance: none;
		background-color: #fff;
		background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
		background-repeat: no-repeat, repeat;
		background-position: right 0.7em top 50%, 0 0;
		background-size: 0.65em auto, 100%;
	}
	.daymeal:focus {
		border: 1px solid var(--theme-primary);
	}
	.daymeal.empty {
		color: rgba(0, 0, 0, 0.6);
	}
	.daymealoption {
		padding: 8px;
		height: 40px;
	}
	.notes {
		position: relative;
		isolation: isolate;
		margin-top: 8px;
	}
</style>
