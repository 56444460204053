<script lang="ts">
	import Textfield from '@smui/textfield';
	import IconButton, { Icon } from '@smui/icon-button';
	import { mdiPlusCircle, mdiDelete } from '@mdi/js';
	import SvgIcon from '$lib/SvgIcon.svelte';

	import type { Ingredient } from '../types';

	export let ingredients: Ingredient[] = [
		{
			id: '1',
			product: '',
			amount: ''
		}
	];

	let updateFocusIdx = ingredients.length;
	let elements = new Array(ingredients.length);
	$: {
		elements = elements.filter((e) => !!e);
		if (elements.length === updateFocusIdx) {
			elements[elements.length - 1]?.focus();
		}
	}

	function addNewRow() {
		ingredients = [
			...ingredients,
			{
				id: `${ingredients.length + 1}`,
				product: '',
				amount: ''
			}
		];
		updateFocusIdx = ingredients.length;
	}
	function removeRow(index: number) {
		ingredients = ingredients.filter((ingredient, i) => i !== index);
		elements = elements.filter((element, i) => i !== index);
		updateFocusIdx = ingredients.length;
	}
	function onKeyDown(e: any) {
		if (e.key === 'Enter') {
			addNewRow();
		}
	}
</script>

<table class="ingredients-grid">
	<thead>
		<tr>
			<th id="ingredient-label">Ingredient</th>
			<th id="amount-label">Amount</th>
			<th />
		</tr>
	</thead>
	<tbody>
		{#each ingredients as ingredient, index (ingredient.id)}
			<tr>
				<td class="ingredient-num">
					<p>Ingredient {index + 1}</p>
				</td>
				<td class="ingredient-item">
					<Textfield
						label="Ingredient"
						class="shaped-outlined new-meal-ingredient"
						variant="outlined"
						on:keydown={onKeyDown}
						bind:value={ingredient.product}
						bind:this={elements[index]}
						autofocus={index === ingredients.length - 1} />
				</td>
				<td class="ingredient-amount">
					<Textfield
						label="Amount"
						bind:value={ingredient.amount}
						on:keydown={onKeyDown}
						class="shaped-outlined new-meal-amount"
						variant="outlined" /></td>
				<td class="ingredient-buttons">
					<IconButton
						on:click={addNewRow}
						title="Add Another Ingredient"
						style="padding: 12px;width:38px">
						<SvgIcon mdiIcon={mdiPlusCircle} />
					</IconButton>
					<IconButton
						on:click={() => removeRow(index)}
						title="Remove Ingredient"
						style="padding: 6px;width:30px">
						<SvgIcon mdiIcon={mdiDelete} />
					</IconButton>
				</td>
			</tr>
		{/each}
	</tbody>
</table>

<style>
	.ingredients-grid {
		display: grid;
		border-collapse: collapse;
		/* min-width: 100%; */
		grid-template-columns: 1fr;
		background-color: var(--theme-on-primary);
	}
	.ingredients-grid thead {
		display: none;
	}
	.ingredient-buttons {
		display: flex;
		justify-content: space-around;
	}
	td.ingredient-item,
	td.ingredient-amount {
		display: flex;
		justify-content: center;
		padding-left: 0;
		padding-right: 0;
	}
	:global(td > label) {
		width: 90%;
	}
	@media (min-width: 582px) {
		.ingredients-grid {
			display: grid;
			border-collapse: collapse;
			/* min-width: 100%; */
			grid-template-columns:
				minmax(225px, 0.9fr)
				minmax(125px, 0.5fr)
				minmax(100px, 0.4fr);
		}
		.ingredients-grid thead {
			display: contents;
		}
		:global(.ingredients-grid .mdc-notched-outline__notch),
		.ingredient-num {
			display: none;
		}
		td.ingredient-item,
		td.ingredient-amount {
			/* margin-left: 0.5rem; */
			padding-left: 8px;
			padding-right: 8px;
		}
	}

	thead,
	tbody,
	tr {
		display: contents;
	}

	th,
	td {
		padding: 5px 5px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	th {
		position: sticky;
		top: 0;
		background: var(--theme-primary);
		color: var(--theme-on-primary);
		text-align: left;
		font-weight: normal;
		z-index: 99;
	}

	th:last-child {
		border: 0;
	}

	td {
		padding-top: 10px;
		padding-bottom: 10px;
	}
</style>
