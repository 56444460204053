<script lang="ts">
	import SvgIcon from './SvgIcon.svelte';
	import { mdiContentCopy, mdiDelete } from '@mdi/js';

	export let isChecked = false;
	export let item: any;
	export let label: string;
	export let amount: string | null = null;
	export let toggleItem: (item: any, e: Event) => void;
	export let removeItem: undefined | ((item: any, e: Event) => void) = undefined;

	const copyMealIngredient = (name: string, e: Event) => {
		if (name) {
			navigator.clipboard.writeText(name);
		}
	};
</script>

<li
	class="check-item"
	class:checked={isChecked}
	on:click={(e) => toggleItem(item, e)}
	on:keypress={(e) => e?.key === 'Enter' && toggleItem(item, e)}
	title="Click to toggle"
	role="checkbox"
	tabIndex="0"
	aria-checked={isChecked}>
	<span>{label} {amount ? `(${amount})` : ''}</span><span
		class="copy"
		title="Copy"
		on:click|stopPropagation={(e) => copyMealIngredient(label, e)}
		on:keypress|stopPropagation={(e) => e?.key === 'Enter' && copyMealIngredient(label, e)}
		><SvgIcon
			mdiIcon={mdiContentCopy}
			style="height:24px; width:24px; padding: 0.25rem; margin-left: 8px;" /></span
	>{#if removeItem}<span
			class="remove"
			title="Remove"
			on:click|stopPropagation={(e) => removeItem && removeItem(item, e)}
			on:keypress|stopPropagation={(e) => e?.key === 'Enter' && removeItem && removeItem(item, e)}>
			<SvgIcon mdiIcon={mdiDelete} style="height:24px; width:24px; padding: 0.25rem 0;" />
		</span>
	{/if}
</li>

<style>
	li {
		list-style-type: none;
		position: relative;
		cursor: pointer;
		display: flex;
		padding: 0.5rem 0;
	}
	li::before {
		content: '☐';
		display: block;
		position: absolute;
		font-size: 1.5rem;
		top: 0.1rem;
		left: -1.5rem;
	}
	@media (min-width: 992px) {
		li {
			padding: 0;
		}
		li::before {
			font-size: 1rem;
			top: 0;
			left: -1rem;
		}
	}

	li.checked {
		text-decoration: line-through;
		color: silver;
	}
	li.checked::before {
		content: '☑';
	}
	@media print {
		li.checked {
			display: none;
		}
	}
	.copy,
	.remove {
		display: none;
		cursor: pointer;
	}
	li:hover .copy,
	li:hover .remove {
		display: block;
	}
	li:hover .copy:hover,
	li:hover .remove:hover {
		color: var(--theme-primary);
	}
	li:hover .copy:active,
	li:hover .remove:active {
		/* If clicking on edge, scale will cause click to affect li as well. This prevents that */
		transform: scale(0.8);
	}
</style>
