<script lang="ts">
	import Button from '@smui/button';
	import Textfield from '@smui/textfield';
	import { mdiBasketPlus, mdiCloseCircleOutline } from '@mdi/js';
	import extraItemStore from '$stores/extraItemStore';
	import userStore from '$stores/userStore';

	import type { ExtraItem } from '../types';
	import { upsertExtraItem, deleteExtraItem } from '$db/extraItemDao';

	import CheckItem from './CheckItem.svelte';
	import { debugLog, getYYYYMMDDFromDateTime } from './utils';
	import SvgIcon from './SvgIcon.svelte';

	let extraItems: ExtraItem[] = [];
	let showAddExtraItemInput = false;
	export let startDate: Date;
	let newItemName = '';
	let startDateNum: number;
	$: {
		startDateNum = getYYYYMMDDFromDateTime(startDate);

		if ($extraItemStore.items) {
			/* Filter on date but ignore TZ */
			extraItems = $extraItemStore.items.filter(
				(item: ExtraItem) =>
					(item.date instanceof Date &&
						getYYYYMMDDFromDateTime(item.date) == getYYYYMMDDFromDateTime(startDate)) ||
					item.date === startDateNum
			);
			debugLog('ShoppingList extraItems filtered on ', startDate, extraItems);
		}
	}

	function addNewItem(newItemName: string, date: Date) {
		if ($userStore?.user?.placeId) {
			const item: ExtraItem = {
				name: newItemName,
				createdOn: new Date(),
				date: startDateNum,
				isChecked: false
			};
			upsertExtraItem($userStore.user.placeId, item);
			resetAddNewItem();
		}
	}

	function resetAddNewItem() {
		showAddExtraItemInput = false;
		newItemName = '';
	}

	function onNewItemKeyDown(event: CustomEvent | KeyboardEvent) {
		const keyEvent = event as KeyboardEvent;
		if (keyEvent.key === 'Enter') {
			addNewItem(newItemName, startDate);
			showAddExtraItemInput = true;
		} else if (keyEvent.key === 'Escape') {
			resetAddNewItem();
		}
	}
	function toggleExtraItem(extraItem: ExtraItem, e: Event) {
		if ($userStore?.user?.placeId) {
			extraItem.isChecked = !extraItem.isChecked;
			debugLog(`toggleExtraItem:`, extraItem);
			upsertExtraItem($userStore?.user?.placeId, extraItem);
		}
	}

	function removeExtraItem(item: ExtraItem, e: Event) {
		if ($userStore?.user?.placeId && item.id) {
			debugLog(`removeExtraItem:`, item);
			deleteExtraItem($userStore?.user?.placeId, item.id);
		}
	}

	function autofocus(element: HTMLElement) {
		debugLog('autofocus', element);
		element.focus();
	}
</script>

<div class="extraItemsWrapper">
	{#if extraItems.length}
		<h4>Other items</h4>
		<ul>
			{#each extraItems as item}
				<CheckItem
					{item}
					label={item.name}
					isChecked={item.isChecked}
					toggleItem={toggleExtraItem}
					removeItem={removeExtraItem} />
			{/each}
		</ul>
	{/if}

	<div class="extraItemsAction">
		{#if !showAddExtraItemInput}
			<Button
				style="font-size: 0.8rem;"
				on:click={() => {
					showAddExtraItemInput = true;
				}}>Add Another Item</Button>
		{/if}
		{#if showAddExtraItemInput}
			<!-- svelte-ignore a11y-autofocus -->
			<Textfield
				style="height: 32px; max-width: 40%;"
				variant="outlined"
				class="addExtraItemInput"
				use={[autofocus]}
				bind:value={newItemName}
				on:keydown={onNewItemKeyDown} />
			<Button
				style="font-size: 0.8rem; padding: 0;"
				title="Add"
				on:click={() => {
					addNewItem(newItemName, startDate);
				}}><SvgIcon mdiIcon={mdiBasketPlus} style="margin-right: 0px;" /></Button
			><Button
				style="font-size: 0.8rem; padding: 0;"
				title="Cancel"
				on:click={() => {
					resetAddNewItem();
				}}><SvgIcon mdiIcon={mdiCloseCircleOutline} style="margin-right: 0px;" /></Button>
		{/if}
	</div>
</div>

<style>
	h4 {
		margin-top: 1rem;
	}
	.extraItemsWrapper {
		padding-top: 0.5rem;
	}

	:global(.addExtraItemInput) {
		margin-left: 41px;
	}
	:global(.addExtraItemInput::before) {
		content: '☐';
		display: block;
		position: absolute;
		top: 0;
		left: -18px;
	}
	:global(.addExtraItemInput .mdc-text-field__input) {
		font-size: 0.8rem;
	}
	:global(.addExtraItemInput .mdc-floating-label) {
		top: 80%;
	}
	@media print {
		.extraItemsAction {
			display: none;
		}
	}
</style>
