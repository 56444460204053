import { derived } from 'svelte/store';
import auth from './authStore';
import userStore from './userStore';
import { listenForExtraItems } from '$db/extraItemDao';
import type { ExtraItemStore } from '../types';

const store = derived(
	[auth, userStore],
	([$auth, $userStore], set: (value: ExtraItemStore) => void) => {
		if ($auth.user && $userStore?.user?.placeId) {
			const unsubscribe = listenForExtraItems($userStore?.user?.placeId, (items) => {
				set({
					loading: false,
					error: null,
					items: items
				});
			});
			return unsubscribe;
		} else {
			set({
				loading: false,
				error: 'You must be logged in to view extra items.',
				items: []
			});
		}
	},
	{ loading: true, error: null, items: [] }
);

export default store;
