/// <reference types="@sveltejs/kit" />

import { derived } from 'svelte/store';

import auth from './authStore';
import meals from './mealStore';
import users from './userStore';
import { listenForPlans } from '$db/planDao';
import type { DayPlan } from '../types';
import type { Meal, PlanStore } from '../types';

const store = derived(
	[auth, users, meals],
	([$auth, $users, $meals], set: (value: PlanStore) => void) => {
		if ($auth.user && $users?.user?.placeId) {
			const unsubscribe = listenForPlans($users?.user?.placeId, (plans) => {
				set({
					loading: false,
					error: null,
					plans: updateMealsInPlans(plans, $meals.meals)
				});
			});
			return unsubscribe;
		} else {
			set({
				loading: false,
				error: 'You must be logged in to view plans.',
				plans: []
			});
		}
	},
	{ loading: true, error: null, plans: [] }
);

const updateMealsInPlans = (plans: DayPlan[], meals: Meal[]) => {
	const updatedPlans = plans.map((plan: DayPlan) => {
		const mealId = plan?.mealId;
		if (mealId) {
			const meal = meals.find((m: Meal) => m.id === mealId);
			return { ...plan, meal };
		} else {
			return plan;
		}
	});
	return updatedPlans;
};

export default store;
