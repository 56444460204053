<script lang="ts">
	import IconButton from '@smui/icon-button';
	import { mdiPrinter } from '@mdi/js';
	import planStore from '$stores/planStore';
	import { isDefaultMealId } from '$stores/mealStore';
	import type { DayPlan, Meal } from '../types';
	import PlanMeal from './PlanMeal.svelte';
	import SvgIcon from './SvgIcon.svelte';
	import { debugLog, prettyPrintDate } from './utils';
	import ShoppingListExtraItems from './ShoppingListExtraItems.svelte';

	export let startDate = new Date();
	let plans: DayPlan[] = [];
	let dedupedPlans: DayPlan[] = [];
	let mealIds: (string | undefined)[] = [];
	let meals: Meal[] | undefined = [];

	function countMealOccurrences(plan: DayPlan) {
		const mealId = plan.mealId;
		const matches = mealIds.filter((id) => id === mealId);
		return matches.length;
	}

	$: {
		/* Use 25 not 24 hours to account for DST ending */
		let endDate = new Date(startDate.getTime() + 6 * 25 * 60 * 60 * 1000);
		if ($planStore.plans) {
			plans = $planStore.plans.filter(
				(plan: DayPlan) =>
					plan.date >= startDate &&
					plan.date <= endDate &&
					plan.mealId &&
					!isDefaultMealId(plan.mealId)
			);
			debugLog(
				`ShoppingList plans from ${prettyPrintDate(startDate)} to ${prettyPrintDate(endDate)}`,
				plans
			);

			mealIds = plans.map((plan: DayPlan) => plan.mealId);

			dedupedPlans = plans.filter((plan: DayPlan, index: number) => {
				const mealId = plan.mealId;
				const occurrences = countMealOccurrences(plan);
				if (occurrences === 1) {
					return true;
				} else {
					return index === mealIds.indexOf(mealId);
				}
			});
			debugLog('Deduped plans', dedupedPlans);
		}
	}
</script>

{#if meals}
	<header>
		<h3>Shopping List</h3>
		{#if plans.length}
			<div class="printer-button">
				<IconButton on:click={(e) => window.print()}>
					<SvgIcon mdiIcon={mdiPrinter} />
				</IconButton>
			</div>
		{/if}
	</header>
	<div class="list">
		<!-- Pass the whole plan as the key because plan ids don't change when meal ids do
       but also meal ids can be duplicated and so are also not good as keys -->
		{#each dedupedPlans as plan (plan)}
			{@const count = countMealOccurrences(plan)}
			<PlanMeal {plan} {count} />
		{/each}
		{#if !plans.length}
			<p>Your shopping list is empty. Select some meals to start building your list.</p>
		{/if}
		<ShoppingListExtraItems {startDate} />
	</div>
{/if}

<style>
	header {
		display: flex;
		justify-content: space-between;
	}
	h3 {
		margin-bottom: 5px;
	}

	.printer-button {
		display: none;
		height: 24px;
		width: 24px;
		margin: -5px 20px 0 0;
	}
	@media (min-width: 992px) {
		.printer-button {
			display: block;
		}
	}
	@media print {
		.list {
			columns: 2;
		}
	}
</style>
