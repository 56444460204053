<script lang="ts">
	import { onMount } from 'svelte';

	import { getMealById } from '$stores/mealStore';
	import type { MealCountEntry } from '../types';
	let mealReport: any;

	export let mealCounts: MealCountEntry[];
	const data = {
		labels: mealCounts.map((m: any) => getMealById(m.mealId)?.name),
		datasets: [
			{
				label: 'Usage',
				data: mealCounts.map((m: any) => m.count),
				borderWidth: 2,
				backgroundColor: ['#428dff66'],
				borderColor: ['#428dff']
			}
		]
	};

	const options = {
		indexAxis: 'y',
		maintainAspectRatio: false,
		// Elements options apply to all of the options unless overridden in a dataset
		// In this case, we are setting the border of each horizontal bar to be 2px wide
		elements: {
			bar: {
				borderWidth: 2
			}
		},
		responsive: true,
		plugins: {
			legend: {
				display: false
			},
			title: {
				display: false
			}
		}
	};
	onMount(async () => {
		const Chart = (await import('chart.js/auto')).default;

		const ctx = mealReport;
		new Chart(ctx, {
			type: 'bar',
			data,
			options
		});
	});
</script>

<div class="chartContainer">
	<canvas bind:this={mealReport} />
</div>

<style>
	.chartContainer {
		padding: 1rem 1rem 0 0.5rem;
		height: calc(100vh - 250px);
		width: 100%;
	}
</style>
