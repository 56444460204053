import {
	addDoc,
	collection,
	doc,
	orderBy,
	query,
	where,
	Timestamp,
	DocumentSnapshot,
	type SnapshotOptions,
	getDocs,
	getDoc,
	onSnapshot,
	type Unsubscribe,
	setDoc,
	type FirestoreDataConverter,
	updateDoc,
	arrayUnion,
	arrayRemove,
	QueryDocumentSnapshot,
	deleteDoc,
	writeBatch,
	getCountFromServer
} from 'firebase/firestore';
import type { DayPlan } from '../types';
import { db } from '../firebase';
import { debugLog, debugWarn, get12MonthRange } from '$lib/utils';

const convertFromTimestamp = (ts: Timestamp) => {
	if (!ts) return null;
	const dt = ts.toDate();
	return new Date(dt.getUTCFullYear(), dt.getUTCMonth(), dt.getUTCDate());
};
const convertToTimestamp = (dt: Date) => {
	const utcDate = new Date(dt.getTime() - dt.getTimezoneOffset() * 60000);
	const ts = Timestamp.fromDate(utcDate);
	return ts;
};

/* The converter is responsible for capturing the Firestore document ids and storing them
   with the data, and then removing it from the data before saving back to Firebase
*/
const DayPlayConverter: FirestoreDataConverter<DayPlan> = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	toFirestore: (plan: DayPlan) => {
		return {
			// Firebase expects a Timestamp object, which is not a Date object.
			date: convertToTimestamp(plan.date),
			notes: plan.notes || '',
			mealId: plan.mealId || '',
			checkedItems: plan.checkedItems || []
		};
	},
	fromFirestore: (snapshot: DocumentSnapshot, options: SnapshotOptions | undefined) => {
		const data = snapshot.data(options);
		return {
			id: snapshot.id,
			// Firebase returns a Timestamp object, which is not a Date object.
			date: convertFromTimestamp(data?.date),
			notes: data?.notes,
			mealId: data?.mealId,
			isSaved: true,
			checkedItems: data?.checkedItems
		} as DayPlan;
	}
};

const getPlans = async (placeId: string, startDate: Date, endDate: Date): Promise<DayPlan[]> => {
	debugLog('Getting plans for ', placeId, startDate, endDate);
	const basePath = `places/${placeId}/plans`;
	const ref = collection(db, basePath).withConverter(DayPlayConverter);

	const q = query(
		ref,
		where('date', '>=', Timestamp.fromDate(startDate)),
		where('date', '<=', Timestamp.fromDate(endDate)),
		orderBy('date', 'asc')
	);
	const querySnapshot = await getDocs(q);
	debugLog(`Found ${querySnapshot.size} plans for ${placeId}`);
	return querySnapshot.docs.map((doc) => doc.data() as DayPlan);
};

const listenForPlans = (placeId: string, callback: (plans: DayPlan[]) => void) => {
	try {
		const basePath = `places/${placeId}/plans`;
		const ref = collection(db, basePath).withConverter(DayPlayConverter);
		const { sixMonthsAgo, sixMonthsFromNow } = get12MonthRange();
		const q = query(
			ref,
			where('date', '>', Timestamp.fromDate(sixMonthsAgo)),
			where('date', '<', Timestamp.fromDate(sixMonthsFromNow)),
			orderBy('date', 'asc')
		);
		const unsubscribe: Unsubscribe = onSnapshot(q, (querySnapshot) => {
			callback(querySnapshot.docs.map((doc) => doc.data() as DayPlan));
		});
		return unsubscribe;
	} catch (error) {
		console.error('api/plans: Error listening for plans', error);
	}
};

const getPlansByDate = async (placeId: string, date: Date) => {
	const basePath = `places/${placeId}/plans`;
	const ref = collection(db, basePath).withConverter(DayPlayConverter);

	const q = query(ref, where('date', '==', convertToTimestamp(date)));
	const querySnapshot = await getDocs(q);
	if (querySnapshot.size > 0) {
		return querySnapshot.docs;
	} else {
		return null;
	}
};

const savePlan = async (placeId: string, plan: DayPlan): Promise<DayPlan> => {
	const basePath = `places/${placeId}/plans`;
	debugLog('Checking for duplicates plans for ', plan.date);
	const existing = await getPlansByDate(placeId, plan.date);
	if (existing) {
		existing.forEach((doc: QueryDocumentSnapshot) => {
			if (!plan.id || doc.id !== plan.id) {
				debugWarn('Found duplicate plan, removing', plan, doc);
				deleteDoc(doc.ref);
			}
		});
	}

	debugLog('Saving plan', plan);
	if (plan.isSaved) {
		const ref = doc(db, basePath + '/' + plan.id).withConverter(DayPlayConverter);
		// Use setDoc not updateDoc b/c withConverter doesn't work with updateDoc
		// See https://github.com/firebase/firebase-js-sdk/issues/4611
		await setDoc(ref, plan);
		return plan;
	} else {
		const collectionRef = collection(db, basePath).withConverter(DayPlayConverter);
		const docRef = await addDoc(collectionRef, plan);
		const docSnapshot = await getDoc(docRef);
		return docSnapshot.data() as DayPlan;
	}
};

/**
 * Updates a set of existing plans. If any plans have not yet been saved, this will throw an error
 * @param placeId
 * @param plans
 */
const updatePlans = async (placeId: string, plans: DayPlan[]) => {
	debugLog('Updating plans in bulk', plans);
	const basePath = `places/${placeId}/plans`;
	const batch = writeBatch(db);
	plans.forEach((plan) => {
		if (plan.isSaved) {
			const ref = doc(db, basePath + '/' + plan.id).withConverter(DayPlayConverter);
			batch.update(ref, plan);
		}
	});
	await batch.commit();
};

const toggleIngredient = async (
	placeId: string,
	planId: string,
	ingredientId: string | undefined,
	isChecked: boolean
) => {
	console.log('toggleIngredient', placeId, planId, ingredientId, isChecked);
	if (ingredientId === undefined) {
		console.warn('Unable to toggle ingredient, no ingredient id provided');
		return;
	}
	const path = `places/${placeId}/plans/${planId}`;
	const ref = doc(db, path).withConverter(DayPlayConverter);
	if (isChecked) {
		await updateDoc(ref, { checkedItems: arrayUnion(ingredientId) });
	} else {
		await updateDoc(ref, { checkedItems: arrayRemove(ingredientId) });
	}
};

const getCountForMeal = async (
	placeId: string,
	mealId: string,
	startDate?: Date,
	endDate?: Date
) => {
	const basePath = `places/${placeId}/plans`;
	const ref = collection(db, basePath);
	const queries = [where('mealId', '==', mealId)];
	if (startDate) {
		queries.push(where('date', '>=', Timestamp.fromDate(startDate)));
	}
	if (endDate) {
		queries.push(where('date', '<=', Timestamp.fromDate(endDate)));
	}
	const q = query(ref, ...queries);
	const aqs = await getCountFromServer(q);
	const count = aqs.data().count;
	console.log('Count for meal', mealId, count);
	return count;
};

export { getPlans, savePlan, updatePlans, toggleIngredient, listenForPlans, getCountForMeal };
